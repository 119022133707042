import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;

  constructor(
    private http: HttpClient,
  ) {
    this.token = localStorage.getItem('tokenguntner');
  }

  login(data: any) {
    return this.http.post<any>(environment.apiUrl + 'authentication', data).pipe(map(res => {
      console.log('AUTH LOGIN token', res);
      //Valida se houve erro no login
      if (res.msgErro != null && res.msgErro != "") {
      //Grava o Token na Sessao Local e Retorna o Token  
      } else {
        if (res.token) {
          this.token = res.token;
          localStorage.setItem('tokenguntner', this.token);
        };
      };

      return res;
    }));
  }

  logout() {
    localStorage.removeItem('tokenguntner');
  }

  decodeToken() {
    try {
      let token = localStorage.getItem('tokenguntner');
      return jwt_decode(token)
    } catch (Error) {
      return null;
    }
  }

}
