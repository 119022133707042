import { Component, Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor() { }
}

export class HammerConfiguration extends HammerGestureConfig {
  overrides = <any>{
      // override hammerjs default configuration
      'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
      'pinch': { enable: false },
      'rotate': { enable: false },
      'pan': { enable: false }
  };
}