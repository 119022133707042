import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import ptBR from '@angular/common/locales/pt';
import { AppComponent, HammerConfiguration } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
// import { SQLite } from '@ionic-native/sqlite/ngx';
//import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
registerLocaleData(ptBR);
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite } from  '@ionic-native/sqlite/ngx';
import { HammerModule } from "../../node_modules/@angular/platform-browser";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    HammerModule
  ],
  providers: [
      SQLite,
      SQLitePorter,
    { 
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy 
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfiguration }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
